import React from 'react'
import {Motion, spring} from 'react-motion';

import SaveScreen from './SaveScreen'
import style from './style.css'
import moment from 'moment'
import auth from './firebaseAuth'

var db = firebase.firestore();
db.enablePersistence()
class FinalCountdown extends React.Component {
    state = {
        isVerified: 'loading',
        auth: {
            displayName: '',
            email: '',
            birthdate: '',
            ageGoal: '',
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged((user) => {
            if (user) {
              // User is signed in.
                this.onUserAuth(user)
            } else {
              // No user is signed in.
                this.setState({
                    isVerified: false,
                })
            }
          });
    }

    fetchUserInfo = (userId) => {
        db.collection('users-profile')
        .doc(userId)
        .onSnapshot(response => {
            const data = response.exists && response.data() || {}

            if (data.birthdate && data.ageGoal) {
                this.setState({
                    loading: false,
                    auth: {
                        ...this.state.auth,
                        birthdate: data.birthdate,
                        ageGoal: data.ageGoal,
                    }
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    onUserAuth = (user) => {        
        if (this.state.auth.birthdate && this.state.auth.ageGoal) {
            this.setState({
                isVerified: true,
                auth: {
                    ...this.state.auth,
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    isAnonymous: user.isAnonymous,
                },
            })

            db.collection('users-profile')
            .doc(user.uid)
            .set({
                birthdate: this.state.auth.birthdate,
                ageGoal: this.state.auth.ageGoal,
            })
        } else {
            this.setState({
                isVerified: true,
                auth: {
                    ...this.state.auth,
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    isAnonymous: user.isAnonymous,
                },
            })
        }

        if (user.uid) {
            this.fetchUserInfo(user.uid)
        }
    }

    submitStep = (event) => {
        event.preventDefault()

        Array.from(event.target.children).map((element) => {
            if (element.name && element.value) {
                this.setState({
                    auth: {
                        ...this.state.auth,
                        [element.name]: element.value,
                    }
                })

                console.log({
                    [element.name]: element.value,
                })
                db.collection('users-profile').doc(this.state.auth.uid).set({
                    [element.name]: element.value,
                }, { merge: true })
            }
        })
    }

    onAnonymousSave = () => {
        this.setState({
            isVerified: false,
            // auth: {
            //     ...this.state.auth,
            // }
        })
    }

    onLogout = () => {
        firebase.auth().signOut()
    }

    clearData = () => {
        localStorage.removeItem('birthdate')
        localStorage.removeItem('ageGoal')

        this.setState({
            auth: {
                ...this.state.auth,
                birthdate: '',
                ageGoal: '',
            }
        })

        if (this.state.auth.uid) {
            db.collection('users-profile').doc(this.state.auth.uid).set({
                birthdate: '',
                ageGoal: '',
            }, { merge: true })
        }
    }


    render() {
        if (this.state.isVerified === 'loading' || this.state.loading) {
            return (
                <div>
                    Loading
                </div>
            )
        }

        if (this.state.isVerified) {
            return (
                <div className={style.internalPageWrap}>
                    <div className={style.header}>
                        <div className={style.firstLine}>
                            {this.state.auth.displayName &&
                                <h1>Hello, {this.state.auth.displayName || 'N/D'}</h1>
                            }

                            {this.state.auth.isAnonymous &&
                                <h1>Hello, Anonymous</h1>
                            }

                            <div className={style.firstButtons}>
                                {!this.state.auth.email && <button onClick={this.onAnonymousSave}>Save with Gmail</button>}
                                {this.state.auth.email && <button onClick={this.onLogout}>lou out</button>}
                                {this.state.auth.birthdate && <button onClick={this.clearData}>re-start</button>}
                            </div>
                        </div>

                        {!this.state.auth.birthdate &&
                            <div>
                                <div className={style.stepNumber}>
                                    <span>1</span> Step
                                </div>
                                <span>First we need to know your birth date</span>
                                    <form onSubmit={this.submitStep}>
                                        <input autoFocus type="date" name="birthdate" required placeholder={'DD/MM/YYYY'} />

                                        <button type="submit">next</button>
                                    </form>
                            </div>
                        }

                        {this.state.auth.birthdate && !this.state.auth.ageGoal &&
                            <>
                                <div className={style.stepNumber}>
                                    <span>2</span> Step
                                </div>

                                <span>Now you need to set an age goal, up to 120 years old</span>

                                <form onSubmit={this.submitStep}>
                                    <input autoFocus type="number" name="ageGoal" min={moment().diff(moment(this.state.auth.birthdate), 'years')} max="120" placeholder="Up to 120 years old" />

                                    <button type="submit">save</button>
                                </form>
                            </>
                        }

                        {this.state.auth.birthdate && this.state.auth.ageGoal &&
                            <>
                                <GoalSummary
                                    birthdate={this.state.auth.birthdate}
                                    ageGoal={this.state.auth.ageGoal}
                                />

                                <WeekSymmaryAndList
                                    birthdate={this.state.auth.birthdate}
                                    ageGoal={this.state.auth.ageGoal}
                                />
                            </>
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <SaveScreen
                    onSuccess={this.onUserAuth}
                    onError={() => null}
                />
            )
        }
    }
}

const GoalSummary = ({ birthdate, ageGoal }) => {
    const birthDate = moment(birthdate)
    const goalDate = birthDate.clone().add(ageGoal, 'years')
    const currentDay = moment().diff(birthDate, 'days')
    const daysLeft = goalDate.diff(moment(), 'days')
    const currentAge = moment().diff(birthDate, 'years')
    const currentMonth = moment().diff(birthDate, 'months')
    const monthsRemaining = goalDate.diff(moment(), 'months')
    const percentageToNextMonth = Math.ceil(100 - ((moment().date() / moment().daysInMonth()) * 100))

    return (
        <div>
            {/* <p>
                You are living month {currentMonth}, {percentageToNextMonth}% left to next month.

                You still have {monthsRemaining} months remaining until you reach {ageGoal} years old.
            </p> */}

            <p>
                This is your {currentDay} day alive. You have {daysLeft} days left to reach {ageGoal} years old.
            </p>
        </div>
    )
}

const WeekSymmaryAndList = ({ birthdate, ageGoal }) => {
        const birthDate = moment(birthdate)
        const goalDate = birthDate.clone().add(ageGoal, 'years')
        const currentAge = moment().diff(birthDate, 'years')
        const currentMonth = moment().diff(birthDate, 'months')
        const weeksRemaining = goalDate.diff(moment(), 'weeks')
        const currentWeek = moment().diff(birthDate, 'weeks')

        const startOfWeek = moment().startOf('week')
        const endOfWeek = moment().endOf('week')
        const weekSecoundsUntilNow = moment().diff(startOfWeek, 'seconds')
        const weekSecoundUntilEnd = endOfWeek.diff(startOfWeek, 'seconds')
        const weekPercentage = Math.ceil((weekSecoundsUntilNow / weekSecoundUntilEnd) * 100)
        const percentageToNextMonth = Math.ceil(100 - ((moment().date() / moment()
            .daysInMonth()) * 100))
        const listOfWeeks = Array.from({ length: weeksRemaining })
            .fill('x')
            .map((x, index) => {
                return {
                    weekNumber: currentWeek + index + 1
                }
            })
    return (
        <div>
            <h2 style={{ margin: '2em 0 1em'}}>
                Current week progress is {weekPercentage}% complete
            </h2>

            <div className={style.weekCard}>
                <Motion defaultStyle={{x: 0}} style={{x: spring(weekPercentage)}}>
                    {value => (
                        <div
                            className={style.weekPercentage}
                            style={{
                                width: value.x + '%',
                            }}
                        >
                            <br />
                        </div>
                    )}
                </Motion>
            </div>

            <h3 style={{ margin: '2em 0 1em'}}>Weeks to come</h3>
 us 
            {listOfWeeks.slice(0, 7).map(({ weekNumber }) => {
                return (
                    <div className={style.weekCard} key={weekNumber}>
                        Week Number {weekNumber}
                    </div>
                )
            })}

            <div className={[style.weekCard, style.moreWeeksToCome].join(' ')}>
                + {listOfWeeks.length - 8} hidden weeks
            </div>
        </div>
    )
}

export default FinalCountdown