import React, { useEffect } from "react";
import style from './style.css'
import auth, { provider } from './firebaseAuth'

const onSignInWithGoogle = (onSuccess, onError) => {
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    return auth.signInWithPopup(provider)
  })
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = result.credential.accessToken;
    // The signed-in user info.
    var user = result.user;

    console.log(user)
    onSuccess(user)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;


    onError(errorCode, errorMessage, email, credential)
    console.log(errorCode, errorMessage, email, credential)
    // ...
  });
}

function saveLater(onSuccess) {
  // onSuccess({
  //   isVerified: true,
  // })

  auth.signInAnonymously()
    .then(r => {
      onSuccess(r.user)
      console.log(r)
    })
    .catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
  });
}

class SaveScreen extends React.Component {
  render() {
    return (
      <>
        <div className={style.saveScreenBg}>  
          <div className={style['page-block']}>
            <h1 className={style.textCenter}>The Final Countdown</h1>
            <p>Get focus and organize your lifetime by circle and timers</p>

            <button
              onClick={() => onSignInWithGoogle(this.props.onSuccess, this.props.onError)}
              className={style['button-primary']}
            >Save with Google</button>
            <span>Or</span>
            <button
              onClick={() => saveLater(this.props.onSuccess)}
              className={style['button-primary']}
            >Save Later</button>
          </div>
        </div>
      </>
    )
  }
}

export default SaveScreen